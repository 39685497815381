import router from '@/app/plugins/router'
import { defineStore } from 'pinia'
import { useContractStore } from '@/features/contracts/stores/contract'
import { erase } from '@/features/contracts/services/contractService'
import * as documentService from '@/features/contracts/services/documentService'

export const useContractDocumentsStore = defineStore('contractDocuments', {
  state: () => ({
    documents: [],
    everyDocumentLoaded: false,
  }),
  actions: {
    async loadDocumentData({ contractId, documentId, type }) {
      const documentData = {
        contractId,
        documentId,
        data: await new Promise((resolve) => {
          if ('doc' === type) {
            documentService.fetchContractDocument({
              attempt: 10,
              contractId,
              currentRouteName: router.currentRoute.name,
              next: (resp) => resolve(resp),
              catchError: (err) => resolve({ error: err }),
            })
          } else {
            resolve(null)
          }
        }),
      }

      const index = this.documents?.findIndex((it) => it.contractId === contractId)

      if (-1 === index) {
        this.documents.push(documentData)
      } else {
        this.documents[index] = documentData
      }
    },
    eraseDocument(contractId) {
      return erase({ contractId, completely: false }).then(() => {
        const contractStore = useContractStore()

        this.documents.splice(
          this.documents.findIndex((d) => d.contractId === contractId),
          1,
        )

        if (0 === this.documents?.length) {
          return router.push({
            name: 'dashboard',
            params: {
              workspace_id: router.currentRoute?.params?.workspaceId,
            },
          })
        }

        if (`${router.currentRoute?.params?.contractId}` !== `${contractId}`) {
          return contractStore.reloadAttachments(
            router.currentRoute?.params?.contractId,
            router.currentRoute?.params?.workspaceId,
          )
        }

        return router.push({
          name: 'contractsCollectionParties',
          params: {
            contractId: this.documents?.find(() => true)?.contractId,
            workspaceId: router.currentRoute?.params?.workspaceId,
          },
        })
      })
    },
    resetDocuments() {
      this.documents = []
    },
  },
  getters: {
    contractsDocuments: (state) => {
      return state.documents
    },
    documentData: (state) => (documentId) => {
      return state.documents?.find((document) => document?.documentId === documentId)
    },
    failedDocuments: (state) => {
      return state.documents?.filter((d) => d?.data?.error) || []
    },
    errorOccured: (state) => {
      return 0 === state.documents?.length || state.documents?.some((document) => document?.data?.error)
    },
    isEveryDocumentLoaded: (state) => {
      const contractStore = useContractStore()

      return (
        state.documents?.map((d) => Object.assign({}, d.data))?.every((data) => undefined !== typeof data) &&
        contractStore.attachments?.length === state.documents?.length &&
        contractStore.attachments?.length > 0
      )
    },
  },
})
