<template>
  <v-container class="pl-0" fluid>
    <v-row align="center" justify="start">
      <v-col class="col-12">
        <v-divider></v-divider>
      </v-col>

      <v-col class="col-12 pb-0">
        <span class="sign-identity__block-title">{{ $t('general.basic') }}</span>
      </v-col>

      <v-col class="col-12">
        <v-radio-group v-model="signIdentity.type" class="mt-0" hide-details row>
          <v-radio :label="$t('workspace.type.fo')" color="#51CF66" value="nature"></v-radio>
          <v-radio :label="$t('workspace.type.osvc_or_po')" color="#51CF66" value="legal"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col v-if="'nature' === signIdentity.type" class="col-12">
        <v-row align="center" justify="start">
          <v-col class="col-12 col-md-4">
            <span class="sign-identity__label">{{ $t('general.birth_date') }}:</span>

            <SBirthDayPicker
              v-model="signIdentity.number"
              :locale="$i18n.locale"
              :label="``"
              class="sign-identity__input mt-2"
            />
          </v-col>
        </v-row>

        <v-row align="center" justify="start">
          <v-col class="col-12 col-md-4">
            <span class="sign-identity__label">{{ $t('company.street_and_number') }}:</span>

            <v-text-field
              v-model="signIdentity.organization_street"
              class="sign-identity__input mt-2"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col class="col-12 col-md-4">
            <span class="sign-identity__label">{{ $t('company.city') }}:</span>

            <v-text-field
              v-model="signIdentity.organization_city"
              class="sign-identity__input mt-2"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col class="col-12 col-md-4">
            <span class="sign-identity__label">{{ $t('company.zip') }}:</span>

            <v-text-field
              v-model="signIdentity.organization_zip"
              class="sign-identity__input mt-2"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="'legal' === signIdentity.type" class="col-12">
        <v-row align="end" justify="start">
          <v-col class="col-12 col-md-4">
            <span class="sign-identity__label"> {{ $t('contract.external_company.ico_placeholder') }}: </span>

            <v-text-field
              v-model.trim="signIdentity.organization_ico"
              class="sign-identity__input mt-2"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col class="col-12 col-md-auto">
            <v-btn
              color="#7031B4"
              class="sign-identity__ico-button"
              :loading="'cs' === loadingIcoData"
              outlined
              @click="loadCompanyData('cs')"
            >
              {{ $t('find.czech_ico') }}
            </v-btn>
          </v-col>

          <v-col class="col-12 col-md-auto">
            <v-btn
              color="#7031B4"
              class="sign-identity__ico-button"
              :loading="'sk' === loadingIcoData"
              outlined
              @click="loadCompanyData('sk')"
            >
              {{ $t('find.slovak_ico') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row align="center" justify="start">
          <v-col class="col-12 col-md-4">
            <span class="sign-identity__label">{{ $t('company.dic') }}:</span>

            <v-text-field
              v-model="signIdentity.organization_dic"
              class="sign-identity__input mt-2"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row align="center" justify="start">
          <v-col class="col-12 col-md-4">
            <span class="sign-identity__label">{{ $t('company.title') }}:</span>

            <v-text-field
              v-model="signIdentity.organization_name"
              class="sign-identity__input mt-2"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col class="col-12 col-md-4">
            <span class="sign-identity__label">{{ $t('workspace.position') }}:</span>

            <v-text-field
              v-model="signIdentity.organization_position"
              class="sign-identity__input mt-2"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row align="center" justify="start">
          <v-col class="col-12 col-md-4">
            <span class="sign-identity__label">{{ $t('company.street_and_number') }}:</span>

            <v-text-field
              v-model="signIdentity.organization_street"
              class="sign-identity__input mt-2"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col class="col-12 col-md-4">
            <span class="sign-identity__label">{{ $t('company.city') }}:</span>

            <v-text-field
              v-model="signIdentity.organization_city"
              class="sign-identity__input mt-2"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col class="col-12 col-md-4">
            <span class="sign-identity__label">{{ $t('company.zip') }}:</span>

            <v-text-field
              v-model="signIdentity.organization_zip"
              class="sign-identity__input mt-2"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row align="center" justify="start">
      <v-col class="col-12">
        <span class="sign-identity__block-title">{{ $t('signature.footer.placeholder') }}</span>
      </v-col>

      <v-col class="col-12 col-md-8">
        <span class="sign-identity__label">{{ $t('footer.text') }}:</span>

        <v-textarea
          v-model="signIdentity.signatureFooter"
          class="sign-identity__input mt-2"
          rows="4"
          hide-details
          outlined
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from 'vue'
import { useWorkspaceStore } from '@/stores/workspace'
import SBirthDayPicker from '@/common/components/SBirthDayPicker'
import { ContractService } from '@/services/ContractService.js'

export default defineComponent({
  name: 'SignIdentitySettings',
  components: { SBirthDayPicker },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const workspaceStore = useWorkspaceStore()

    return {
      workspaceStore,
    }
  },
  data() {
    return {
      footerSettings: null,
      loadingIcoData: null,
    }
  },
  computed: {
    signIdentity: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    'signIdentity.firstname': {
      handler: function (value, oldValue) {
        if (value !== oldValue && '' !== oldValue) {
          this.updateFooter('firstname', value)
        }
      },
    },
    'signIdentity.lastname': {
      handler: function (value, oldValue) {
        if (value !== oldValue && '' !== oldValue) {
          this.updateFooter('lastname', value)
        }
      },
    },
    'signIdentity.organization_name': {
      handler: function (value, oldValue) {
        if (value !== oldValue && '' !== oldValue) {
          this.updateFooter('organizationName', value)
        }
      },
    },
    'signIdentity.organization_position': {
      handler: function (value, oldValue) {
        if (value !== oldValue && '' !== oldValue) {
          this.updateFooter('organizationPosition', value)
        }
      },
    },
  },
  mounted() {
    this.workspaceStore.fetchSignatureFooterSettings(this.$route.params?.workspaceId).then((resp) => {
      this.footerSettings = resp

      if (!this.signIdentity?.signatureFooter) {
        this.initializeSignatureFooter()
      }
    })
  },
  methods: {
    initializeSignatureFooter() {
      this.updateFooter('firstname', this.signIdentity?.firstname || '')
      this.updateFooter('lastname', this.signIdentity?.lastname || '')
      this.updateFooter('organizationName', this.signIdentity?.organization_name || '')
      this.updateFooter('organizationPosition', this.signIdentity?.organization_position || '')
    },
    updateFooter(item, value) {
      if (this.signIdentity.signatureFooter && this.signIdentity.is_proposer) {
        return
      }

      const footerPreview = {
        firstname: this.workspaceStore.signatureFooterSettings?.enabledName ? this.signIdentity?.firstname || '' : null,
        lastname: this.workspaceStore.signatureFooterSettings?.enabledLastname
          ? this.signIdentity?.lastname || ''
          : null,
        organizationName: this.workspaceStore.signatureFooterSettings?.enabledOrganizationName
          ? this.signIdentity?.organization_name || ''
          : null,
        organizationPosition: this.workspaceStore.signatureFooterSettings?.enabledOrganizationPosition
          ? this.signIdentity?.organization_position || ''
          : null,
      }

      if (null === footerPreview[`${item}`]) {
        return
      }

      footerPreview[`${item}`] = value

      this.signIdentity.signatureFooter = [
        [footerPreview?.firstname || '', footerPreview?.lastname || '']?.filter((item) => item)?.join(' '),
        [footerPreview?.organizationName || '', footerPreview?.organizationPosition || '']
          ?.filter((item) => item)
          ?.join(', '),
      ].join('\n')
    },
    loadCompanyData(countryCode) {
      this.loadingIcoData = countryCode

      ContractService.searchExternalDB(this.signIdentity.organization_ico, countryCode).then((resp) => {
        let organizationStreet = resp?.street || resp?.city

        if (resp.number) {
          organizationStreet = `${organizationStreet} ${resp.number}`
        } else {
          organizationStreet = ''
        }

        Object.assign(this.signIdentity, {
          organization_city: resp?.city || this.signIdentity?.organization_city,
          organization_street: organizationStreet || this.signIdentity?.organization_street,
          organization_dic: resp?.dic || this.signIdentity?.organization_dic,
          organization_ico: resp?.ico || this.signIdentity?.organization_ico,
          organization_zip: resp?.zip || this.signIdentity?.organization_zip,
          firstname: 'legal' != resp.type ? resp.firstname || this.signIdentity?.firstname : this.signIdentity?.firstname,
          lastname: 'legal' != resp.type ? resp.lastname || this.signIdentity?.lastname : this.signIdentity?.lastname,
          organization_name: resp.organizationName || this.signIdentity?.organization_name,
        })

        this.$notification.success(this.$t('general.data_updated'))
      })
      .catch(() => {
        this.$notification.error(this.$t('general.data_not_updated'))
      })
      .finally(() => {
        this.loadingIcoData = null
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.sign-identity {
  &__block-title {
    color: #424242;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22.5px;
  }

  &__label {
    color: #868e96;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
    text-transform: uppercase;
  }

  &__input {
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #ffffff;
    color: #424242;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17.5px;
  }

  &__ico-button {
    box-sizing: border-box;
    height: 40px !important;
    border: 2px solid #7031b4;
    border-radius: 4px;
  }
}
</style>
