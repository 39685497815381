import { internalApiRequest } from '@/shared/services/apiService'

/**
 * @return {Promise<AxiosResponse<*>>}
 */
export const fetchVerificationTypes = () => {
  return internalApiRequest({
    method: 'GET',
    path: `api/public/v1/enum/auth/type`,
  })
}

/**
 * @param {Number|String} workspaceId
 * @param {Number|String} groupId
 * @return {Promise<AxiosResponse<*>>}
 */
export const fetchVerificationGroup = ({ workspaceId, groupId }) => {
  return internalApiRequest({
    method: 'GET',
    path: `api/v2/workspace/${workspaceId}/auth/settings/group/predefined/${groupId}`,
  })
}

/**
 * @param {Number|String} workspaceId
 * @return {Promise<AxiosResponse<*>>}
 */
export const fetchVerificationGroupsList = (workspaceId) => {
  return internalApiRequest({
    method: 'GET',
    path: `api/v2/workspace/${workspaceId}/auth/settings/group/predefined/list`,
  })
}

/**
 * @param {Number|String} contractId
 * @param {Number|String} docsPersonId
 * @return {Promise<AxiosResponse<*>>}
 */
export const fetchDocsPersonGroups = ({ contractId, docsPersonId }) => {
  return internalApiRequest({
    method: 'GET',
    path: `api/v2/contract/${contractId}/docsPerson/${docsPersonId}/auth/group`,
  })
}

/**
 * @param {Number|String} contractId
 * @param {Number|String} groupId
 * @param {Object} data
 * @return {Promise<AxiosResponse<*>>}
 */
export const saveDocsPersonVerificationGroup = ({ contractId, groupId, data }) => {
  return internalApiRequest({
    method: 'POST',
    path: `api/v2/contract/${contractId}/auth/setting/group/createFromPredefined/${groupId}`,
    data,
  })
}

/**
 * @param {Number|String} contractId
 * @param {Number|String} groupId
 * @param {Object} data
 * @return {Promise<AxiosResponse<*>>}
 */
export const resetDocsPersonVerificationGroup = ({ contractId, groupId, data }) => {
  return internalApiRequest({
    method: 'DELETE',
    path: `api/v2/contract/${contractId}/auth/setting/group/deleteFromPredefined/${groupId}`,
    data,
  })
}
