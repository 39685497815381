import { validate } from 'email-validator'
import parsePhoneNumber from 'libphonenumber-js'

/**
 * @param {String} email
 * @returns {boolean} isValid
 */
export const isEmailValid = (email) => {
  return validate(email)
}

/**
 * @param {String} phoneNumber
 * @returns {boolean} isValid
 */
export const isPhoneNumberValid = (phoneNumber) => {
  if (phoneNumber.startsWith('+997')) {
    return parsePhoneNumber(`${phoneNumber.replace('+997', '+7')}`)?.isValid()
  }

  return parsePhoneNumber(`${phoneNumber}`)?.isValid() || false
}

/**
 * @param {String} fullName
 * @returns {boolean} isValid
 */
export const isFullNameValid = (fullName) => {
  return 1 < fullName?.split(' ')?.length
}
