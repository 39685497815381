import { defineStore } from 'pinia'
import { useSignIdentitiesStore } from '@/features/contracts/stores/signIdentities'
import * as workspaceService from '@/stores/services/workspaceService'

export const useSignIdentityProposerRolesStore = defineStore('signIdentityProposerRoles', {
  state: () => ({
    roles: [],
  }),
  actions: {
    fetchProposerRoles(workspaceId) {
      return workspaceService.fetchMembersJobsPositions(workspaceId).then((roles) => {
        this.roles = roles
      })
    },
  },
  getters: {
    proposerRoles: (state) => {
      const signIdentitiesStore = useSignIdentitiesStore()
      const jobPositions = state.roles?.map((a) => ({ ...a }))

      for (const signer of signIdentitiesStore.signIdentities?.filter((s) => s.is_proposer) || []) {
        const position = jobPositions.find(
          (position) => position.name === signer?.workspace_position || position.name === signer?.variable_position,
        )

        if (position) {
          position.numberOfPeopleWithThatPosition -= 1
        }
      }

      return jobPositions || []
    },
    availableProposersRoles: (state) => {
      return state.proposerRoles
        ?.map((item, index) => ({ id: index, ...item }))
        ?.filter((r) => r?.numberOfPeopleWithThatPosition > 0)
    },
  },
})
