import router from '@/app/plugins/router'
import { defineStore } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useContractStore } from '@/features/contracts/stores/contract'
import { useContractSignTypesStore } from '@/features/contracts/stores/contractSignTypes'
import { useWorkspaceStore } from '@/stores/workspace'
import * as signIdentitiesService from '@/features/contracts/services/signIdentitiesService'

export const defaultSignIdentity = {
  type: 'nature',
  firstname: '',
  lastname: '',
  email: '',
  mobile: '',
  variable_position: null,
  invalid: true,
  signatureFooter: '',
  one_device_group: null,
}

export const useSignIdentitiesStore = defineStore('signIdentities', {
  state: () => ({
    list: [],
    error: false,
  }),
  actions: {
    async fetchSignIdentities(contractId) {
      return new Promise((resolve, reject) => {
        signIdentitiesService.fetchSignIdentities({
          contractId,
          currentRouteName: router.currentRoute.name,
          next: (resp) => {
            this.list = resp?.map((s) => ({ ...s, invalid: false }))

            const contractStore = useContractStore()
            const availableProposers = contractStore.availableProposers
            const defaultAttributes = {
              is_proposer: true,
              party_order: 1,
              personalNewNotification: '',
            }

            if (!resp?.find((s) => (s?.email || s?.variable_position) && s?.is_proposer)) {
              if (1 === availableProposers?.length) {
                this.list?.push({
                  ...availableProposers[0],
                  ...defaultAttributes,
                  one_device_group: availableProposers[0].one_device_group || null,
                })
              } else {
                const userStore = useUserStore()
                const preselectedProposer = availableProposers?.find(
                  (proposer) => `${proposer?.email}` === `${userStore.user?.email}`,
                )

                if (preselectedProposer) {
                  this.list?.push({
                    ...preselectedProposer,
                    ...defaultAttributes,
                    one_device_group: preselectedProposer.one_device_group || null,
                  })
                }
              }
            }

            resolve()
          },
          catchError: (err) => {
            reject(err)
          },
        })
      })
    },
    addSignIdentity(data = undefined, isProposer = false) {
      const contractsSignTypes = useContractSignTypesStore()
      const workspaceStore = useWorkspaceStore()

      this.list.push({
        ...defaultSignIdentity,
        required_verifications: workspaceStore.workspaceContractSettings.is2FAEnabled ? ['sms_pin'] : [],
        is_proposer: isProposer,
        ...data,
        party_order: this.lastSignIdentityPartyOrder,
        contract_role: contractsSignTypes.availableTypes[0]?.value,
        personalNewNotification: '',
      })
    },
    resetSignIdentities() {
      this.list = []
    },
    removeSignIdentity(signIdentityIndex) {
      this.list?.splice(signIdentityIndex, 1)
    },
    updateSignIdentitiesPersonalizedNotification(notificationText) {
      this.list?.forEach((signIdentity) => {
        signIdentity.personalNewNotification = signIdentity?.personalNewNotification || notificationText
      })
    },
  },
  getters: {
    signIdentities: (state) => {
      return state.list?.map((s) => ({ ...s, partyOrder: s?.party_order }))
    },
    signIdentityById: (state) => (signIdentityId) => {
      return state.list?.find((signIdentity) => signIdentityId === signIdentity?.id)
    },
    docsPersonsIdsFromList: (state) => {
      return state.list?.map((signIdentity) => signIdentity.docs_person_id)
    },
    docsPersonIdBySignIdentityEmail: (state) => (signIdentityEmail) => {
      return state.list?.find((signIdentity) => signIdentity.email === signIdentityEmail)?.docs_person_id || null
    },
    signIdentitiesPartiesOrders: (state) => {
      const partiesList = [...new Set(state.list?.map((s) => s?.party_order))]

      return Array.from(Array(partiesList?.length ? Math.max.apply(this, partiesList) : [1]).keys(), (_, i) => i + 1)
    },
    canAddProposerParty: (state) => {
      const contractStore = useContractStore()

      return contractStore.availableProposers?.length > state.list?.filter((s) => s?.is_proposer)?.length
    },
    canAddNewSignIdentityParty: () => {
      // TODO - check if it's logical to set new party order
      return true
    },
    lastSignIdentityPartyOrder: (state) => {
      return Math.max.apply(this, [...state.signIdentitiesPartiesOrders])
    },
    errorOccured: (state) => {
      const isMissingProposer = !state.list?.some((signIdentity) => signIdentity?.is_proposer)

      const isMissingFirstnameOrLastname = state.list?.some((signIdentity) => (signIdentity?.firstname === '' || signIdentity?.lastname === '') && signIdentity?.variable_position === null)
      
      if (isMissingProposer) {
        return true
      }

      if (isMissingFirstnameOrLastname) {
        return true
      }

      return state.list?.some((signIdentity) => (!signIdentity?.variable_position ? signIdentity?.invalid : false))
    },
  },
})
