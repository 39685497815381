<script setup>
import { computed } from 'vue'
import { useSignIdentitiesStore } from '@/features/contracts/stores/signIdentities'

const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
})

const signIdentitiesStore = useSignIdentitiesStore()
const { updateSignIdentitiesPersonalizedNotification } = signIdentitiesStore

const personalNewNotification = computed({
  get() {
    return props.value?.personalNewNotification || ''
  },
  set(val) {
    props.value.personalNewNotification = val
  },
})

function usePersonalizedNotificationForAll() {
  updateSignIdentitiesPersonalizedNotification(personalNewNotification.value)
}
</script>

<template>
  <v-container class="pl-0" fluid>
    <v-row align="end" justify="start">
      <v-col class="col-12">
        <v-divider></v-divider>
      </v-col>

      <v-col class="col-12">
        <span class="personalized-notification__block-title">{{ $t('personalized_notification.title') }}</span>
      </v-col>

      <v-col class="col-12 col-md-8">
        <v-textarea
          v-model="personalNewNotification"
          :label="$t('general.message_content')"
          class="personalized-notification__input"
          auto-grow
          outlined
          rows="3"
          hide-details
        ></v-textarea>
      </v-col>

      <v-col class="col-12 col-md-4">
        <v-btn
          color="primary"
          :block="$vuetify.breakpoint.mobile"
          :disabled="!personalNewNotification"
          class="underlined"
          text
          @click="usePersonalizedNotificationForAll"
        >
          {{ $t('personalized_notification.copy_action') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped lang="scss">
.personalized-notification {
  &__block-title {
    color: #424242;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22.5px;
  }

  &__input {
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #ffffff;
    color: #424242;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17.5px;
  }
}
</style>
