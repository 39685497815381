<template>
  <v-row align="center" justify="center" no-gutters>
    <v-col class="col-12">
      <ContractNotice
        v-for="(contractNotice, index) in contractNotices"
        :contract-notice="contractNotice"
        :contract-notice-index="index"
        :key="index"
      />
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useContractNoticesStore } from '@/features/contracts/stores/contractNotices'
import ContractNotice from '@/features/contracts/components/ContractNotice.vue'

export default defineComponent({
  name: 'ContractNotices',
  components: {
    ContractNotice,
  },
  setup() {
    const contractNoticesStore = useContractNoticesStore()

    const contractNotices = computed(() => contractNoticesStore.notices)

    return {
      contractNotices,
    }
  },
})
</script>
<style lang="scss" scoped></style>
