<template>
  <v-autocomplete
    v-model="selectedIdentity"
    class="settings-input"
    :readonly="isDisabled"
    :append-icon="isDisabled ? '' : 'mdi-menu-down'"
    :rules="fullNameRules"
    :items="itemsList"
    :item-text="(item) => getItemPreview(item)"
    item-value="email"
    hide-selected
    dense
    @blur="fillEmpty"
  >
    <template v-slot:item="{ item, on, attrs }">
      <v-list-item class="search-input__options-menu-content" link @click="onSelectData(item)" v-bind="attrs" v-on="on">
        <v-list-item-content>
          <v-list-item-title class="search-input__options-menu-content--name">
            {{ getItemPreview(item) }}
          </v-list-item-title>

          <v-list-item-subtitle class="search-input__options-menu-content--email">
            {{ item.email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SignIdentitySelect',
  props: {
    signIdentity: {
      type: Object,
      default: null,
    },
    itemsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedIdentity: null,
      fullNameRules: [(v) => !!v || this.$t('login.name_invalid')],
    }
  },
  computed: {
    isDisabled() {
      return 0 === this.itemsList?.filter((i) => i?.email !== this.signIdentity?.email)?.length
    },
  },
  watch: {
    signIdentity: {
      immediate: true,
      handler: function (val) {
        this.selectedIdentity = val
      },
    },
  },
  methods: {
    getItemPreview(identity) {
      return `${identity?.firstname || ''} ${identity?.lastname || ''}`?.trim()
    },
    fillEmpty() {
      if (null === this.selectedIdentity) {
        this.selectedIdentity = this.signIdentity
      }
    },
    onSelectData(identity) {
      this.selectedIdentity = Object.assign({}, identity)
      this.$emit('on-select-sign-identity', Object.assign({}, identity))
    },
  },
})
</script>

<style lang="scss">
.v-menu__content {
  max-width: 300px;
  width: 100%;
  border: 1px solid #7031b4;
  border-radius: 4px !important;
  background-color: #ffffff !important;
}

.search-input__options-menu-content--name {
  color: #424242;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17.5px;
}

.search-input__options-menu-content--email {
  color: #868e96;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
}

.settings-input {
  color: #424242;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;

  &--disabled {
    opacity: 0.5;
    color: #868e96;
  }
}
</style>
