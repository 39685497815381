import { defineStore } from 'pinia'
import * as predefinedWorkflowService from '@/features/contracts/services/predefinedWorkflowService'

export const usePredefinedWorkflowsStore = defineStore('predefinedWorkflows', {
  state: () => ({
    templatesList: [],
  }),
  actions: {
    fetchSettingsTemplatesList(workspaceId) {
      return predefinedWorkflowService.fetchTemplates(workspaceId).then((resp) => {
        this.templatesList = [...resp]
      })
    },
    createNewSettingsTemplate({ workspaceId, data }) {
      return predefinedWorkflowService.createTemplate({ workspaceId, data }).then((resp) => {
        this.templatesList = [...this.templatesList, resp]
      })
    },
    deleteSettingsTemplate({ workspaceId, templateId }) {
      return predefinedWorkflowService.deleteTemplate({ workspaceId, templateId }).then(() => {
        this.templatesList = [...this.templatesList.filter((t) => t?.id !== templateId)]
      })
    },
    resetTemplatesList() {
      this.templatesList = []
    },
  },
  getters: {
    predefinedWorkflowsList: (state) => {
      return state.templatesList || []
    },
  },
})
