<template>
  <ContractNoticeForm
    v-if="contractNotice.isEditing"
    :contract-notice="contractNotice"
    :contract-notice-index="contractNoticeIndex"
    @save-notice="saveContractNotice"
  />

  <v-card v-else class="py-5 pl-5 pr-2 mb-4" outlined>
    <v-row>
      <v-col cols="auto" class="d-flex align-center">
        <span>
          {{
            `${$t('contract.notice.content')} ${noticeRecipient}, ${$tc('contract.notice.days', noticeDeliverTime, {
              count: noticeDeliverTime,
            })} ${$t(noticeType).toLowerCase()}`
          }}
        </span>
      </v-col>
      <v-col class="text-end">
        <v-btn icon @click="editContractNotice">
          <v-icon color="#95AFDA">mdi-pencil-outline</v-icon>
        </v-btn>
        <v-btn icon @click="removeContractNotice">
          <v-icon color="#95AFDA">mdi-trash-can-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { defineComponent } from 'vue'
import { useContractNoticesStore } from '@/features/contracts/stores/contractNotices'
import { useSignIdentitiesStore } from '@/features/contracts/stores/signIdentities'
import ContractNoticeForm from './ContractNoticeForm.vue'

export default defineComponent({
  name: 'ContractNotice',
  components: { ContractNoticeForm },
  props: {
    contractNotice: {
      type: Object,
      required: true,
    },
    contractNoticeIndex: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const contractNoticesStore = useContractNoticesStore()
    const signIdentitiesStore = useSignIdentitiesStore()

    return {
      contractNoticesStore,
      signIdentitiesStore,
    }
  },
  computed: {
    signIdentities() {
      return this.signIdentitiesStore?.list || []
    },
    noticeRecipient() {
      const signIdentity = this.signIdentities?.find(
        (signIdentity) => signIdentity?.email === this.contractNotice?.recipients[0],
      )

      return 'all' === this.contractNotice?.recipients[0]
        ? this.$t('contract.notice.all').toLowerCase()
        : `${signIdentity?.firstname} ${signIdentity?.lastname}`
    },
    noticeDeliverTime() {
      return this.contractNotice?.noticeTime?.value
    },
    noticeType() {
      return `contract.notice.${this.contractNotice?.emailNotificationType}`
    },
  },
  methods: {
    editContractNotice() {
      this.contractNoticesStore.editNotice(this.contractNoticeIndex)
    },
    saveContractNotice(data) {
      this.contractNoticesStore.saveNotice(this.contractNoticeIndex, data)
    },
    removeContractNotice() {
      this.contractNoticesStore.removeNotice(this.contractNoticeIndex)
    },
  },
})
</script>

<style></style>
