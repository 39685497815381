<template>
  <v-row class="uploaded-item mt-6 mx-auto" align="start" justify="space-between" no-gutters>
    <v-col class="col-auto pr-2">
      <v-icon color="#95AFDA" small>mdi-dots-grid</v-icon>
    </v-col>

    <v-col class="col h-100 align-self-center px-2">
      <v-row align="center" justify="center" no-gutters>
        <v-col class="col-12 pb-2 d-flex flex-row justify-space-between align-center">
          <span class="uploaded-item__file-title pr-1">{{ uploadFile.name }}</span>

          <v-btn v-if="hasUploadError" text small @click="$emit('upload-again')">
            <v-icon color="#7031B4" small left>mdi-restart</v-icon>
            {{ $t('upload.retry') }}
          </v-btn>

          <span v-else class="uploaded-item__info pl-1 pt-1">
            {{ progressSummary }}
          </span>
        </v-col>

        <v-expand-transition>
          <v-col v-if="isUploading" class="col-12 pb-2">
            <v-progress-linear
              height="8px"
              :value="uploadingProgress"
              :color="uploadingColor"
              :indeterminate="false"
              :query="true"
              rounded
            ></v-progress-linear>
          </v-col>
        </v-expand-transition>

        <v-col class="col-12">
          <span class="uploaded-item__size" :class="{ 'uploaded-item__info-error': hasUploadError }">
            {{ formattedSize }}
          </span>

          <v-tooltip v-if="isMainDocument" top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="mx-2 pa-2 py-3 font-weight-bold"
                color="#EDF5FD"
                text-color="#4B639C"
                x-small
                v-bind="attrs"
                v-on="on"
                style="border-radius: 13px"
              >
                {{ $t('upload.main_document') }}
              </v-chip>
            </template>

            <span>{{ $t('main_document.info') }}</span>
          </v-tooltip>
        </v-col>

        <v-expand-transition>
          <v-col v-if="showExtensionWarning" class="col-12 py-2 d-flex flex-row align-start flex-">
            <v-icon color="#F7A604" small>mdi-alert</v-icon>
            <span class="uploaded-item__warning pl-2">{{ $t('upload.format_alert') }}</span>
          </v-col>
        </v-expand-transition>
      </v-row>
    </v-col>

    <v-col class="col-auto pl-2">
      <v-icon v-if="isUploading && !hasUploadError" color="#95AFDA" small> mdi-close-circle </v-icon>

      <v-icon v-if="isSuccessfullyUploaded" color="#35D883" small>mdi-check-circle</v-icon>

      <v-btn small icon @click="$emit('delete-uploaded-file')">
        <v-icon :color="hasUploadError ? '#E03428' : '#95AFDA'" small>mdi-trash-can</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from 'vue'
import pathParse from 'path-parse'

export default defineComponent({
  name: 'FileUploadBoxUploadedItem',
  props: {
    uploadFile: {
      type: Object,
      required: true,
    },
    isMainDocument: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isUploading() {
      if (!this.uploadFile?.response) {
        return true
      }

      return 100 !== this.uploadingProgress
    },
    isSuccessfullyUploaded() {
      return true === this.uploadFile?.success
    },
    hasUploadError() {
      return !!this.uploadFile?.error
    },
    progressSummary() {
      if (100 === this.uploadingProgress) {
        return this.$t('general.done')
      }

      return `${this.uploadingProgress}%`
    },
    formattedSize() {
      if (this.hasUploadError) {
        return this.$t('upload.failed')
      }

      if (0 === this.uploadFile?.size) {
        return '0 Byte'
      }

      const i = parseInt(Math.floor(Math.log(this.uploadFile?.size) / Math.log(1024)))

      return `${Math.round(this.uploadFile?.size / Math.pow(1024, i), 2)} ${['Bytes', 'KB', 'MB', 'GB', 'TB'][i]}`
    },
    uploadingProgress() {
      return parseInt(this.uploadFile?.progress)
    },
    uploadingColor() {
      return this.uploadFile.error ? '#D7141A' : '#35D883'
    },
    showExtensionWarning() {
      if (this.isUploading) {
        return false
      }

      const fileExtension = pathParse(this.uploadFile?.name)?.ext || ''

      return !['.pdf', '.docx', '.xlsx', '.odt', '.odf', '.jpg', '.jpeg', '.png']?.includes(fileExtension.toLowerCase())
    },
  },
})
</script>

<style lang="scss" scoped>
.uploaded-item {
  max-width: 475px;

  &__file-title {
    color: #495057;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
  }

  &__info {
    color: #95afda;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17.5px;
  }

  &__info-error {
    color: #e03428 !important;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17.5px;
  }

  &__size {
    color: #868e96;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17.5px;
  }

  &__warning {
    color: #495057;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17.5px;
  }
}
</style>
