<template>
  <v-container
    class="file-upload-box__wrapper ma-0 pa-0"
    :class="{ 'file-upload-box__wrapper--dragging': dragging }"
    @dragenter="dragging = true"
    @dragleave="dragging = false"
  >
    <div v-if="hasUploadedFiles" class="file-upload-box">
      <div class="uploaded-files">
        <span class="uploaded-files__title">{{ $t('general.uploaded') }}</span>
      </div>

      <drop-list :items="uploadedFiles" @reorder="$event.apply(uploadedFiles)">
        <template v-slot:item="{ item }">
          <drag :key="item.id" :data="item" style="width: 100%">
            <FileUploadBoxUploadedItem
              :upload-file="item"
              :is-main-document="isMainContract(item)"
              @delete-uploaded-file="deleteDocumentDialogMethod(true, item)"
              @upload-again="
                $refs.filesInput.update(item, {
                  active: true,
                  error: '',
                  progress: '0.00',
                })
              "
            />
          </drag>
        </template>
      </drop-list>

      <div class="uploaded-files__actions mt-6">
        <label v-if="hasUploadedFiles" for="document" class="file-upload-box__upload-next px-4 px-md-5">
          <v-icon class="pr-2" color="#7031B4" small>mdi-tray-arrow-up</v-icon>
          {{ $t('upload.add_more') }}
        </label>

        <v-btn
          v-if="canContinue"
          color="primary"
          :loading="uploadingFiles"
          class="file-upload-box__upload-label"
          @click="uploadFiles"
        >
          {{ $t('contract.create_draft') }}
        </v-btn>
      </div>
    </div>

    <div v-else class="file-upload-box">
      <v-img class="mb-6" :src="uploadDocumentSrc" max-height="122.96px" max-width="137px" contain></v-img>

      <span class="file-upload-box__title mb-6">{{ $t('upload.drop_zone') }}</span>

      <span class="file-upload-box__separator mb-6">{{ $t('general.or') }}</span>

      <label for="document" class="file-upload-box__upload-label d-flex align-center justify-center">
        <v-icon color="white" small left>mdi-tray-arrow-up</v-icon>

        <span class="upload-label__text">
          {{ $t('contract.choose_file') }}
        </span>
      </label>

      <v-checkbox v-model="convertToPdfA" class="my-5 px-2" hide-details dense>
        <template v-slot:label>
          <span class="options-menu__content--label">
            {{ $t('document.convert_to_pdf_a') }}
          </span>
        </template>

        <template v-slot:append>
          <s-help>
            <template v-slot:content>
              <p
                class="mb-0"
                v-html="
                  $t('document.convert_to_pdf_a.help', {
                    link: $t('links.confluence.convert_to_pdf_a'),
                  })
                "
              ></p>
            </template>
          </s-help>
        </template>
      </v-checkbox>
    </div>

    <file-upload
      ref="filesInput"
      v-model="uploadedFiles"
      name="document"
      :drop="true"
      :post-action="uploadSettings.url"
      :headers="uploadSettings.headers"
      :multiple="multipleUpload"
      @input-filter="inputFilter"
      @input-file="inputFile"
    ></file-upload>

    <SConfirmDialog
      v-model="deleteDocumentDialogObject.show"
      :message="`${$t('docs.delete.confirm')}`"
      @confirmation-callback="removeUploadedFile(deleteDocumentDialogObject.file)"
    />
  </v-container>
</template>

<script>
import { defineComponent } from 'vue'
import { Drag, DropList } from 'vue-easy-dnd'
import { environment } from '@/config/environment'
import { useContractStore } from '@/features/contracts/stores/contract'
import pathParse from 'path-parse'
import FileUpload from 'vue-upload-component'
import SConfirmDialog from '@/common/components/SConfirmDialog'
import FileUploadBoxUploadedItem from '@/common/components/FileUploadBoxUploadedItem'
import { useContractSettingsStore } from '@/features/contracts/stores/contractSettings'

export default defineComponent({
  name: 'FileUploadBox',
  components: {
    FileUploadBoxUploadedItem,
    Drag,
    DropList,
    FileUpload,
    SConfirmDialog,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    multipleUpload: {
      type: Boolean,
      default: false,
    },
    noMainContract: {
      type: Boolean,
      default: false,
    },
    isReplace: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const contractStore = useContractStore()
    const contractSettingsStore = useContractSettingsStore()

    return {
      contractStore,
      contractSettingsStore,
    }
  },
  data() {
    return {
      uploadingFiles: false,
      convertToPdfA: false,
      dragging: false,
      deleteDocumentDialogObject: {
        show: false,
        file: {},
      },
    }
  },
  computed: {
    uploadedFiles: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    uploadSettings() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        url: `${environment.getApiUrl()}/api/v2/documents?convert=${this.convertToPdfA ? 1 : 0}`,
      }
    },
    canContinue() {
      return this.uploadedFiles?.length > 0 && this.uploadedFiles?.every((file) => file.response?.id)
    },
    uploadDocumentSrc() {
      return require('@/features/contracts/assets/undraw_add_files_re_v09g.svg')
    },
    hasUploadedFiles() {
      return 0 < this.uploadedFiles?.length
    },
  },
  watch: {
    uploadedFiles: {
      deep: true,
      handler: function () {
        setTimeout(() => {
          this.dragging = false
        }, 500)
      },
    },
  },
  mounted() {
    this.convertToPdfA = this.contractSettingsStore.convertDocumentsToPdfA
  },
  methods: {
    checkFileExtension(fileName) {
      const fileExtension = pathParse(fileName)?.ext || ''

      return !['.pdf', '.docx', '.xlsx', '.odt', '.odf', '.jpg', '.jpeg', '.png']?.includes(fileExtension.toLowerCase())
    },
    deleteDocumentDialogMethod(show, file) {
      this.deleteDocumentDialogObject = Object.assign({}, { show, file })
    },
    removeUploadedFile(selectedFile) {
      this.uploadedFiles = this.uploadedFiles.filter((f) => f !== selectedFile)
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }

        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }

        if (
          newFile.file &&
          'image/' === newFile.type.substr(0, 6) &&
          this.autoCompress > 0 &&
          this.autoCompress < newFile.size
        ) {
          newFile.error = 'compressing'
          // eslint-disable-next-line no-undef
          const imageCompressor = new ImageCompressor(null, {
            convertSize: Infinity,
            maxWidth: 512,
            maxHeight: 512,
          })

          imageCompressor
            .compress(newFile.file)
            .then((file) => {
              this.$refs.filesInput.update(newFile, {
                error: '',
                file,
                size: file.size,
                type: file.type,
              })
            })
            .catch((err) => {
              this.$refs.filesInput.update(newFile, {
                error: err.message || 'compress',
              })
            })
        }
      }

      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        const URL = window.URL || window.webkitURL

        newFile.blob = URL?.createObjectURL ? URL?.createObjectURL(newFile?.file) : ''
        newFile.thumb = newFile?.blob && 'image/' === newFile?.type?.substr(0, 6) ? newFile.blob : ''
      }

      if (
        newFile &&
        'denied' === newFile.error &&
        newFile.name.indexOf('docx') !== -1 &&
        newFile.response &&
        newFile.response.title &&
        newFile.response.title.indexOf('Nepodporovaný formát') !== -1 &&
        !newFile.active
      ) {
        this.$notification.error(this.$t('upload.error_docs'))
      }
    },
    inputFile(newFile, oldFile) {
      if (newFile && oldFile) {
        if (newFile.active && !oldFile.active) {
          if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
            this.$refs.filesInput.update(newFile, { error: 'size' })
          }
        }
      }

      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (!this.$refs.filesInput.active) {
          this.$refs.filesInput.active = true
        }
      }
    },
    isMainContract(uploadedFile) {
      if (this.noMainContract) {
        return false
      }

      return uploadedFile.id === uploadedFile[0].id && this.uploadedFiles.length > 1
    },
    uploadFiles() {
      this.uploadingFiles = true
      this.$emit('on-upload-documents')
    },
  },
})
</script>

<style lang="scss" scoped>
.file-upload-box {
  display: flex;
  max-width: 736px;
  min-height: 361px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__wrapper {
    box-sizing: border-box;
    max-width: 736px;
    min-height: 361px;
    border: 1px dashed #b4c1d6;
    border-radius: 4px;
    background-color: #ffffff;
    margin: 0 auto;

    &--dragging {
      border: 2px solid var(--v-primary-base);
    }
  }

  &__title {
    color: #424242;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 27.5px;
    text-align: center;
  }

  &__separator {
    color: #424242;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17.5px;
  }

  &__upload-label {
    height: 40px;
    padding: 0 1rem;
    border-radius: 4px;
    background: linear-gradient(90deg, #6226a3 0%, #934fdd 100%);
    color: white;
    cursor: pointer;

    .upload-label__text {
      height: 17px;
      width: 103px;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 17px;
    }
  }

  &__upload-next {
    height: 17px;
    color: #424242;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
    cursor: pointer;
  }
}

.uploaded-files {
  max-width: 475px;
  width: 100%;

  &__title {
    color: #424242;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22.5px;
  }
}

.uploaded-files__actions {
  max-width: 475px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.credits-cost__info {
  color: #868e96;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17.5px;
}
</style>
