import { defineStore } from 'pinia'
import * as templatesService from '@/features/dashboard/services/templatesService'

export const useTemplatesStore = defineStore('templates', {
  state: () => ({
    last: [],
    list: [],
  }),
  actions: {
    fetchWorkspaceTemplates(workspaceId) {
      return templatesService.fetchWorkspaceTemplates(workspaceId).then((resp) => {
        this.last = resp?.last_used || []
        this.list = resp?.templates || []
      })
    },
    resetList() {
      this.last = []
      this.list = []
    },
  },
  getters: {
    all: (state) => {
      return state.list
    },
    lastUsed: (state) => {
      return state.last
    },
    publicTemplates: (state) => {
      return state.list?.filter((t) => !t?.workspace_id)
    },
    workspaceTemplates: (state) => {
      return state.list?.filter((t) => t?.workspace_id)
    },
  },
})
