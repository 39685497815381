<template>
  <v-card class="py-5 pl-5 pr-2 mb-4" outlined>
    <v-row>
      <v-col class="col-9 col-md-4">
        <span class="contract-notice__search--label">
          {{ $t('contract.notice.recipient_title') }}
        </span>

        <v-select
          v-model="selectedNoticeRecipients"
          :items="signIdentities"
          :item-text="getFullName"
          item-value="email"
          :placeholder="`${$t('general.select')}`"
          append-icon="mdi-chevron-down"
          class="contract-notice__select--input"
          height="38px"
          solo
          hide-details
          flat
          return-object
        ></v-select>
      </v-col>

      <v-col class="col-8 col-md-4">
        <span class="contract-notice__search--label">
          {{ $t('contract.notice.deliver_title') }}
        </span>

        <v-select
          v-model="noticeTime"
          :items="noticeTimeItems"
          item-text="name"
          item-value="value"
          :placeholder="`${$t('general.select')}`"
          :error="noticeTimeError"
          append-icon="mdi-chevron-down"
          class="contract-notice__select--input"
          height="38px"
          solo
          hide-details
          flat
        ></v-select>
      </v-col>

      <v-col class="col-12 col-md-4 d-flex align-end justify-end pb-4">
        <v-btn icon :disabled="noticeTimeError" @click="saveContractNotice">
          <v-icon color="primary">mdi-content-save-outline</v-icon>
        </v-btn>

        <v-btn icon @click="removeContractNotice">
          <v-icon color="#95AFDA">mdi-trash-can-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-radio-group v-model="noticeType" hide-details row>
      <v-radio :label="$t('contract.notice.after_send')" value="after_send" color="#4ACE62"></v-radio>
      <v-radio
        :label="$t('contract.notice.before_sign_expiration')"
        value="before_sign_expiration"
        color="#4ACE62"
      ></v-radio>
    </v-radio-group>
  </v-card>
</template>

<script>
import { defineComponent } from 'vue'
import { useContractNoticesStore } from '@/features/contracts/stores/contractNotices'
import { useSignIdentitiesStore } from '@/features/contracts/stores/signIdentities'
import { useContractStore } from '@/features/contracts/stores/contract'

export default defineComponent({
  name: 'ContractNoticeForm',
  props: {
    contractNotice: {
      type: Object,
      required: true,
    },
    contractNoticeIndex: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const contractStore = useContractStore()
    const contractNoticesStore = useContractNoticesStore()
    const signIdentitiesStore = useSignIdentitiesStore()

    return {
      contractStore,
      contractNoticesStore,
      signIdentitiesStore,
    }
  },
  data() {
    return {
      noticeRecipients: null,
      noticeTime: null,
      noticeType: 'after_send',
      noticeTimeItems: [],
      noticeTimeError: false,
    }
  },
  computed: {
    selectedNoticeRecipients: {
      get() {
        return this.noticeRecipients
      },
      set(value) {
        this.noticeRecipients =
          'all' === value
            ? {
                firstname: `${this.$t('contract.for').charAt(0).toUpperCase()}${this.$t('contract.for').slice(1)}`,
                lastname: this.$t('contract.notice.all').toLowerCase(),
                email: 'all',
              }
            : value
      },
    },
    signIdentities() {
      return [
        {
          firstname: `${this.$t('contract.for').charAt(0).toUpperCase()}${this.$t('contract.for').slice(1)}`,
          lastname: this.$t('contract.notice.all').toLowerCase(),
          email: 'all',
        },
        ...this.signIdentitiesStore?.list,
      ]
    },
  },
  watch: {
    noticeTime: {
      handler: function () {
        this.noticeTimeError = false
      },
    },
  },
  created() {
    this.fillNoticeTimeItems()
  },
  mounted() {
    if (0 === this.contractNotice?.recipients?.length || 1 < this.contractNotice?.recipients?.length) {
      this.noticeRecipients = {
        firstname: `${this.$t('contract.for').charAt(0).toUpperCase()}${this.$t('contract.for').slice(1)}`,
        lastname: this.$t('contract.notice.all').toLowerCase(),
        email: 'all',
      }
    } else {
      this.noticeRecipients = this.signIdentities?.find((s) => s.email === this.contractNotice?.recipients[0])
    }

    this.noticeTime = this.contractNotice?.noticeTime?.value || 1
    this.noticeType = this.contractNotice?.emailNotificationType
  },
  methods: {
    fillNoticeTimeItems() {
      for (let i = 1; i <= 30; i++) {
        let obj = {
          name: this.$tc('contract.notice.days', i, { count: i }),
          value: i,
        }

        this.noticeTimeItems.push(obj)
      }
    },
    getFullName(user) {
      return `${user?.firstname} ${user?.lastname}`
    },
    saveContractNotice() {
      const noticeData = {
        emailNotificationType: this.noticeType,
        noticeTime: {
          value: this.noticeTime,
        },
        isEditing: false,
        recipients: [this.noticeRecipients.email],
      }

      if ('after_send' === this.noticeType) {
        const today = new Date()
        today.setDate(today.getDate() + this.noticeTime)
        const day = today.getDate().toString().padStart(2, '0')
        const month = (today.getMonth() + 1).toString().padStart(2, '0')
        const year = today.getFullYear()
        const hours = today.getHours().toString().padStart(2, '0')
        const minutes = today.getMinutes().toString().padStart(2, '0')
        const seconds = today.getSeconds().toString().padStart(2, '0')

        noticeData.deliverAt = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      }

      if ('before_sign_expiration' === this.noticeType) {
        if (this.contractStore?.contract?.waiting_limit < this.noticeTime) {
          this.noticeTimeError = true
          return
        }

        const today = new Date()

        if (
          this.contractStore?.contract?.waiting_limit < 2 ||
          this.contractStore?.contract?.waiting_limit === this.noticeTime
        ) {
          today.setHours(23, 59, 0, 0)
        } else {
          today.setDate(today.getDate() + this.contractStore?.contract?.waiting_limit - this.noticeTime)
        }

        const day = today.getDate().toString().padStart(2, '0')
        const month = (today.getMonth() + 1).toString().padStart(2, '0')
        const year = today.getFullYear()
        const hours = today.getHours().toString().padStart(2, '0')
        const minutes = today.getMinutes().toString().padStart(2, '0')
        const seconds = today.getSeconds().toString().padStart(2, '0')

        noticeData.deliverAt = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      }

      this.$emit('save-notice', noticeData)
    },
    removeContractNotice() {
      this.contractNoticesStore.removeNotice(this.contractNoticeIndex)
    },
  },
})
</script>

<style lang="scss" scoped>
.contract-notice {
  background: none;
  margin-bottom: 1rem;

  @media only screen and (min-width: 960px) {
    background: #ffffff;
    padding: 0.5rem 1rem 1.5rem 1rem;
  }

  &__search--label {
    display: inline-block;
    text-transform: uppercase;
    color: #868e96;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
    margin-bottom: 9px;
  }

  &__select--input {
    border: 1px solid #ced4da;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
  }

  &__select--input.error--text {
    border-color: #ff0000;
  }
}
</style>
