<template>
  <article>
    <v-row
      class="mx-auto my-0"
      :class="{ collapsed: !expandList }"
      style="transition: all 0.3s"
      align="center"
      justify="center"
      no-gutters
    >
      <v-col v-for="contractAttachment in sortedContractAttachments" :key="contractAttachment.id" class="col-12">
        <ContractDocumentStatePreview :contract-attachment="contractAttachment" />
      </v-col>
    </v-row>

    <v-row class="mt-2" align="center" justify="center" no-gutters>
      <v-col v-if="canShowExpandButton" class="col-12 text-center">
        <v-btn
          color="primary"
          :outlined="expandList"
          min-width="8rem"
          :text="!expandList"
          @click="expandList = !expandList"
        >
          {{ $t(`general.block.${expandList ? 'collapse' : 'expand'}`) }}
          <v-icon color="primary" right>
            {{ `mdi-chevron-${expandList ? 'up' : 'down'}` }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </article>
</template>

<script>
import { defineComponent } from 'vue'
import { useContractDocumentsStore } from '@/features/contracts/stores/contractDocuments'
import { useContractSignTypesStore } from '@/features/contracts/stores/contractSignTypes'
import ContractDocumentStatePreview from '@/features/contracts/components/ContractDocumentStatePreview'

export default defineComponent({
  name: 'ContractDocumentStatePreviews',
  components: {
    ContractDocumentStatePreview,
  },
  props: {
    contractAttachments: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const contractDocumentsStore = useContractDocumentsStore()
    const contractSignTypesStore = useContractSignTypesStore()

    return {
      contractDocumentsStore,
      contractSignTypesStore,
    }
  },
  data() {
    return {
      expandList: true,
    }
  },
  computed: {
    canShowExpandButton() {
      return 3 <= this.contractAttachments?.length
    },
    listOfFailedContractIds() {
      return this.contractDocumentsStore.failedDocuments?.map((d) => d.contractId)
    },
    sortedContractAttachments() {
      return this.contractAttachments?.sort(
        (a, b) => this.listOfFailedContractIds?.includes(b.id) - this.listOfFailedContractIds?.includes(a.id),
      )
    },
    contractDocuments() {
      return this.contractDocumentsStore.contractsDocuments
    },
  },
  watch: {
    contractAttachments: {
      handler: function (value) {
        this.contractDocumentsStore.everyDocumentLoaded = value?.length === this.contractDocuments?.length
      },
    },
    contractDocuments: {
      handler: function (value) {
        this.contractDocumentsStore.everyDocumentLoaded = value?.length === this.contractAttachments?.length
      },
    },
  },
  mounted() {
    this.expandList = 3 > this.contractAttachments?.length
  },
})
</script>

<style lang="scss" scoped>
.collapsed {
  max-height: 192px;
  overflow-y: hidden;
}
</style>
