<template>
  <v-dialog :value="true" max-width="920px" width="100%" @keydown.esc="$emit('close')">
    <v-card flat>
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-container class="pb-10 pt-0 px-4 px-md-16 ma-0">
        <v-row class="pb-6 px-md-7" align="center" justify="center" no-gutters>
          <v-col class="col-12 text-center">
            <FileUploadBox
              v-model="uploadedFiles"
              :multiple-upload="multipleUpload"
              :no-main-contract="true"
              :is-replace="!!replaceContractId"
              @on-upload-documents="uploadDocuments"
            />
          </v-col>
        </v-row>

        <v-row
          v-if="areTemplatesEnabled"
          class="pb-6 px-md-7"
          align="center"
          justify="start"
          no-gutters
          style="min-height: 60px"
        >
          <v-col class="col-12 col-md-auto mr-md-5">
            <span class="templates__title">
              {{ showAllContracts ? $t('templates') : $t('dashboard.favourite_patterns') }}
            </span>
          </v-col>

          <v-col class="col-12 col-md-auto">
            <v-btn v-show="!showAllContracts" text @click="showAllContracts = true">
              <v-icon color="primary" left>mdi-folder-multiple</v-icon>

              <span class="contract-settings__select-language--title">
                {{ $t('dashboard.show_contract_patterns') }}
              </span>
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-if="areTemplatesEnabled" class="px-md-7" align="center" justify="space-between" no-gutters>
          <template v-if="(showAllContracts ? templates : lastUsedTemplates).length">
            <v-col
              v-for="template in showAllContracts ? templates : lastUsedTemplates"
              :key="template.id"
              class="col-12 pb-4"
            >
              <v-row class="templates__template-item pa-6" align="center" justify="start" no-gutters>
                <v-col class="col-12 col-md-5 mb-4 mb-md-0">
                  <v-icon color="#95AFDA">mdi-file</v-icon>
                  <span class="templates__item-title">
                    {{ template.title }}
                  </span>
                </v-col>

                <v-col class="col-6 col-md-5">
                  <span
                    class="templates__item-price text-uppercase"
                    :class="{ 'templates__item-price--green': 0 === template.credit_cost }"
                  >
                    {{
                      parseInt(template.credit_cost)
                        ? `${$t('contract.buy_short.text')} ${$tc(
                            'contract.buy_short.x_credits',
                            template.credit_cost,
                            { count: template.credit_cost },
                          )}`
                        : $t('contract.template_free')
                    }}
                  </span>
                </v-col>

                <v-col class="col-6 col-md-auto text-right text-md-left">
                  <v-btn
                    color="primary"
                    class="templates__item-btn"
                    :loading="addingTemplate === template.id"
                    outlined
                    @click="onAddTemplate(template)"
                  >
                    {{ $t('general.create') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </template>

          <v-col v-else class="col-12 pb-4">
            <span class="templates__item-title">{{
              showAllContracts ? $t('dashboard.any_templates') : $t('dashboard.any_favourite_templates')
            }}</span>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from 'vue'
import { useContractStore } from '@/features/contracts/stores/contract'
import { useTemplatesStore } from '@/features/dashboard/stores/templates'
import { useUserStore } from '@/stores/user'
import { useWorkspaceStore } from '@/stores/workspace'
import { getErrorResponseMessage } from '@/common/reusable/errorResponse'
import pathParse from 'path-parse'
import FileUploadBox from '@/common/components/FileUploadBox'

export default defineComponent({
  name: 'ContractDocumentAddAttachment',
  components: {
    FileUploadBox,
  },
  props: {
    addNewAttachment: {
      type: Boolean,
      default: true,
    },
    multipleUpload: {
      type: Boolean,
      default: false,
    },
    replaceContractId: {
      type: [Number, String],
      default: null,
    },
  },
  setup() {
    const contractStore = useContractStore()
    const templatesStore = useTemplatesStore()
    const userStore = useUserStore()
    const workspaceStore = useWorkspaceStore()

    return {
      contractStore,
      templatesStore,
      userStore,
      workspaceStore,
    }
  },
  data() {
    return {
      uploadedFiles: [],
      addingTemplate: null,
      showAllContracts: false,
    }
  },
  computed: {
    availableCredits() {
      return 'legal' === this.workspaceStore.workspace?.type
        ? this.workspaceStore.workspace?.owner_credits
        : this.userStore.userProfileCredits
    },
    areTemplatesEnabled() {
      return !this.replaceContractId && this.workspaceStore.workspaceContractSettings?.can_create_form_contract
    },
    lastUsedTemplates() {
      return this.templatesStore.lastUsed?.slice(0, 2)
    },
    templates() {
      return this.templatesStore.all
    },
  },
  mounted() {
    if (this.areTemplatesEnabled) {
      // TODO - loading templates
      this.loadingTemplates = true
      this.templatesStore.fetchWorkspaceTemplates(this.$route.params?.workspaceId).then(() => {
        this.loadingTemplates = false
      })
    }
  },
  methods: {
    onAddTemplate(template) {
      this.addingTemplate = template.id
      this.contractStore
        .addAttachments(this.contractStore.mainContractId, [
          {
            title: template.title,
            type: 'form',
            number: '',
            tvid: template.id,
            workspace_id: this.$route.params.workspaceId,
            contract_id: this.$route.params.contractId,
          },
        ])
        .catch((err) => {
          if ('Uživatel nemá dostatek kreditů' === err) {
            return (() => {
              this.$notification.error(this.$t('upload.error_credits'))
              this.$router.push({ name: 'subscription' })
            })()
          }

          this.$notification.error(this.$t('upload.error_general'))
        })
        .finally(() => {
          this.addingTemplate = null
          this.$emit('close')
        })
    },
    uploadDocuments() {
      if (this.availableCredits < this.uploadedFiles?.length) {
        this.$notification.error(this.$t('upload.error_credits'))

        return this.$router.push({ name: 'subscription' })
      }

      try {
        const requests = this.uploadedFiles?.map((file) => ({
          title: pathParse(file.name)?.name || file.name,
          type: 'doc',
          document: file.response.id,
          workspace_id: this.$route.params?.workspaceId,
        }))

        // Replace document
        if (this.replaceContractId) {
          return this.contractStore
            .updateContractData({
              contractId: this.replaceContractId,
              data: {
                document_id: requests[0].document,
              },
            })
            .finally(() => {
              this.$emit('close')
            })
        }

        // Add new attachments
        this.contractStore.addAttachments(this.contractStore.mainContractId, requests).finally(() => {
          this.$emit('close')
        })
      } catch (e) {
        if (e?.translationKey) {
          return this.$notification.error(getErrorResponseMessage(e))
        }

        if ('Uživatel nemá dostatek kreditů' === e?.message || 'Uživatel nemá dostatek kreditů' === e?.statusText) {
          this.$notification.error(this.$t('upload.error_credits'))

          return this.$router.push({ name: 'subscription' })
        }

        this.$notification.error(this.$t('upload.error_general'))
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.templates {
  &__title {
    color: #424242;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22.5px;
  }

  &__template-item {
    box-sizing: border-box;
    min-height: 88px;
    border: 1px solid #d4dff0;
    border-radius: 5px;
    background-color: #ffffff;
  }

  &__item-title {
    color: #424242;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17.5px;
  }

  &__item-price {
    color: #7031b4;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;

    &--green {
      color: #35d883;
    }
  }

  &__item-btn {
    height: 40px;
    width: 84px;
    border: 2px solid #7031b4;
    border-radius: 4px;
  }
}
</style>
