import { internalApiRequest } from '@/shared/services/apiService'
import router from '@/app/plugins/router'

/**
 * @type {(function({attempt: *, contractId: *, currentRouteName: *, next: *, catchError: *}): void)|*}
 * @param {Object} payload
 * @param {Number} payload.attempt
 * @param {Number|String} payload.contractId
 * @param {String} payload.currentRouteName
 * @param {Function} payload.next
 * @param {Function} payload.catchError
 */
export const fetchContractDocument = (function () {
  return function ({ attempt, contractId, currentRouteName, next, catchError }) {
    internalApiRequest({
      method: 'GET',
      path: `api/v2/contracts/${contractId}/document`,
    })
      .then(async (resp) => {
        next(resp)
      })
      .catch((err) => {
        if ([404, 424].includes(err.response.status)) {
          return catchError(err?.response?.data)
        }

        if (router.currentRoute.name === currentRouteName && 0 < attempt) {
          return setTimeout(function () {
            fetchContractDocument({
              attempt: attempt - 1,
              currentRouteName,
              contractId,
              next,
              catchError,
            })
          }, 2500)
        }

        catchError(err?.response?.data)
      })
  }
})()

/**
 * @type {(function({attempt: *, contractId: *, currentRouteName: *, next: *, catchError: *}): void)|*}
 * @param {Object} payload
 * @param {Number} payload.attempt
 * @param {Number|String} payload.contractId
 * @param {String} payload.currentRouteName
 * @param {Function} payload.next
 * @param {Function} payload.catchError
 */
export const fetchContractDocumentSignTypes = (function () {
  return function ({ attempt, contractId, currentRouteName, next, catchError }) {
    internalApiRequest({
      method: 'GET',
      path: `api/v2/contract/${contractId}/enum/signTypes`,
    })
      .then(async (resp) => {
        next(resp)
      })
      .catch((err) => {
        if (424 === err.response.status) {
          return catchError(err?.response?.data)
        }

        if (router.currentRoute.name === currentRouteName && 0 < attempt) {
          return setTimeout(function () {
            fetchContractDocumentSignTypes({
              attempt: attempt - 1,
              currentRouteName,
              contractId,
              next,
              catchError,
            })
          }, 2500)
        }

        catchError(err?.response?.data)
      })
  }
})()

/**
 * @param {Number|String} contractId
 * @return {Promise<AxiosResponse<*>>}
 */
export const downloadPdfFile = (contractId) => {
  return internalApiRequest({
    method: 'GET',
    path: `api/v1/contracts/${contractId}/pdf`,
    responseType: 'arraybuffer',
  })
}
