import router from '@/app/plugins/router'
import { internalApiRequest } from '@/shared/services/apiService'

/**
 * @param {Object} signIdentity
 * @return {boolean}
 */
export const isEmptySignIdentity = (signIdentity) => {
  return !signIdentity?.variable_position && !signIdentity?.email && !signIdentity?.firstname && !signIdentity?.lastname
}

/**
 * @param {Number|String} contractId
 * @return {Promise<AxiosResponse<*>>}
 */
export const fetchCurrentSignIdentity = (contractId) => {
  return internalApiRequest({
    method: 'GET',
    path: `api/v2/contract/${contractId}/signIdentity/current`,
  })
}

/**
 * @type {(function({attempt: *, contractId: *, currentRouteName: *, next: *, catchError: *}): void)|*}
 * @param {Object} payload
 * @param {Number} payload.attempt
 * @param {Number|String} payload.contractId
 * @param {String} payload.currentRouteName
 * @param {Function} payload.next
 * @param {Function} payload.catchError
 */
export const fetchSignIdentities = (function () {
  return function ({ attempt = 10, contractId, currentRouteName, next, catchError }) {
    internalApiRequest({
      method: 'GET',
      path: `api/v2/contract/${contractId}/signIdentity/list`,
    })
      .then((resp) => {
        next(resp)
      })
      .catch((err) => {
        if (router.currentRoute.name === currentRouteName && 0 < attempt) {
          return setTimeout(function () {
            fetchSignIdentities({
              attempt: attempt - 1,
              contractId,
              currentRouteName,
              next,
              catchError,
            })
          }, 2500)
        } else {
          catchError(err)
        }
      })
  }
})()

/**
 * @param {String} companyIco
 * @param {String} companyCountry
 * @return {Promise<AxiosResponse<*>>}
 */
export const loadCompanyInfo = ({ companyIco, companyCountry }) => {
  return internalApiRequest({
    method: 'GET',
    path: `api/v1/company?country=${companyCountry}&ico=${companyIco}`,
  })
}
